import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, datas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header1', alignment: 'center' })
        const allRowsHead = [
          []
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '13%', '15%', '2%', '18%', '15%', '2%', '18%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        // pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.fiscal_year'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerSales.allocationType'), style: 'th', alignment: 'center' },
            { text: vm.$t('pusti_mapping.month'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerConfig.fertilizer_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('dealer_panel.requisition_amount'), style: 'th', alignment: 'center' },
            { text: vm.$t('dealer_management.allocation_amount'), style: 'th', alignment: 'center' },
            { text: vm.$t('dealer_panel.stock_balance'), style: 'th', alignment: 'center' }
          ]
        ]

        let totalRequisition = 0
        let totalAllocation = 0
        let totalStock = 0

        datas.map((data, index) => {
          totalRequisition += data.requisition_qty
          totalAllocation += data.allocation_qty
          totalStock += data.stock_qty
          allRows.push([
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.fiscal_year : data.fiscal_year_bn, style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.allocation_type_name : data.allocation_type_name_bn, style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.month_name : data.month_name_bn, style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? data.fertilizer_name : data.fertilizer_name_bn, style: 'td', alignment: 'center' },
            { text: vm.$n(data.requisition_qty, { useGrouping: false }), style: 'td', alignment: 'right' },
            { text: vm.$n(data.allocation_qty, { useGrouping: false }), style: 'td', alignment: 'right' },
            { text: vm.$n(data.stock_qty, { useGrouping: false }), style: 'td', alignment: 'right' }
          ])
        })

        allRows.push([
            { text: vm.$t('globalTrans.total'), style: 'td', alignment: 'center', colSpan: 5 },
            {},
            {},
            {},
            {},
            { text: vm.$n(totalRequisition, { useGrouping: false }), style: 'td', alignment: 'right' },
            { text: vm.$n(totalAllocation, { useGrouping: false }), style: 'td', alignment: 'right' },
            { text: vm.$n(totalStock, { useGrouping: false }), style: 'td', alignment: 'right' }
        ])

        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['7%', '*', '*', '*', '*', '*', '*', '*'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 11,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 11,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header1: {
              fontSize: 14,
              margin: [0, 15, 0, 0]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).download('scheme-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
