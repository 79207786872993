<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('dealer_panel.fertilizer_allotment_report') }}</h4>
        </template>
        <template v-slot:body>
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
              <b-row>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                         :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Month" vid="month_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="month_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('pusti_mapping.month')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.month_id"
                        :options="monthList"
                        id="month_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Allocation Type" vid="allocation_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="allocation_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('fertilizerSales.allocationType')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.allocation_type_id"
                        :options="allocationTypeList"
                        id="allocation_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                  <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{  $t('dealer_panel.fertilizer_allotment_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                              <b-button variant="primary" @click="pdfExport" class="mr-2">
                              {{  $t('globalTrans.export_pdf') }}
                              </b-button>
                              <export-excel
                                class="btn btn-success mr-2"
                                :data="dataCustomizeExcel"
                                :fields="json_fields"
                                :title="$t('dealer_panel.fertilizer_allotment_report')"
                                worksheet="Fertilizer Allotment Report"
                                name="Fertilizer Allotment Report.xls">
                                {{ $t('globalTrans.export_excel') }}
                              </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                        {{ $t('dealer_panel.fertilizer_allotment_report') }}
                                </list-report-head>
                            </b-col>
                              </b-row>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <!-- <b-table-simple bordered hover small responsive  class="text-center">
                                  <b-thead class="text-center">
                                    <b-tr>
                                      <b-th rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th rowspan="2">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                      <b-th rowspan="2">{{ $t('fertilizerSales.allocationType') }}</b-th>
                                      <b-th rowspan="2">{{ $t('pusti_mapping.month') }}</b-th>
                                      <b-th colspan="3">{{ $t('externalUserIrrigation.requisition') }}</b-th>
                                      <b-th colspan="3">{{ $t('externalUserIrrigation.allocation') }}</b-th>
                                      <b-th colspan="3">{{ $t('externalUserIrrigation.stock') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th>{{ $t('externalUserIrrigation.tsp') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.mop') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.dap') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.tsp') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.mop') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.dap') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.tsp') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.mop') }}</b-th>
                                      <b-th>{{ $t('externalUserIrrigation.dap') }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(allo, index) in allocationReport.allocations" :key="index">
                                      <b-td>{{ $n(index + 1)}}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? allo.fiscal_year : allo.fiscal_year_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? allo.allocation_type_name : allo.allocation_type_name_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? allo.month_name : allo.month_name_bn }}</b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple> -->

                                <b-table-simple bordered hover small responsive  class="text-center">
                                  <b-thead class="text-center">
                                    <b-tr>
                                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th>{{ $t('globalTrans.fiscal_year') }}</b-th>
                                      <b-th>{{ $t('fertilizerSales.allocationType') }}</b-th>
                                      <b-th>{{ $t('pusti_mapping.month') }}</b-th>
                                      <b-th>{{ $t('fertilizerConfig.fertilizer_name') }}</b-th>
                                      <b-th>{{ $t('dealer_panel.requisition_amount') }}</b-th>
                                      <b-th>{{ $t('dealer_management.allocation_amount') }}</b-th>
                                      <b-th>{{ $t('dealer_panel.stock_balance') }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(data, index) in allocationReport" :key="index">
                                      <b-td>{{ $n(index + 1) }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? data.fiscal_year : data.fiscal_year_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? data.allocation_type_name : data.allocation_type_name_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? data.month_name : data.month_name_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? data.fertilizer_name : data.fertilizer_name_bn }}</b-td>
                                      <b-td>{{ $n(data.requisition_qty, { useGrouping: false }) }}</b-td>
                                      <b-td>{{ $n(data.allocation_qty, { useGrouping: false }) }}</b-td>
                                      <b-td>{{ $n(data.stock_qty, { useGrouping: false }) }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                      <b-th colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                                      <b-th>{{ $n(totalRequisition) }}</b-th>
                                      <b-th>{{ $n(totalAllocation) }}</b-th>
                                      <b-th>{{ $n(totalStock) }}</b-th>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>

                                 <!-- <b-table bordered hover :items="allocationReport" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                  <template v-slot:cell(requisition_qty)="data">
                                    {{ $n(data.item.requisition_qty, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(allocation_qty)="data">
                                    {{ $n(data.item.allocation_qty, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(stock_qty)="data">
                                    {{ $n(data.item.stock_qty, { useGrouping: false }) }}
                                  </template>
                                </b-table> -->
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingListfertilizer, fertilizerAllotmentReport } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import ExportPdf from '@/Utils/export-pdf'
import ExportPdf from './export_pdf_details'
import flatpickr from 'flatpickr'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Fiscal Year': 'fiscalYear',
  'Allocation Type': 'allocationTypeName',
  'Month Name': 'monthName',
  'Fertilizer Name': 'fertilizerName',
  'Requisition Amount': 'requisitionQty',
  'Allocation Amount': 'allocationQty',
  'Stock Balance': 'stockQty'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'অর্থ বছর': 'fiscalYear',
  'বরাদ্দের ধরণ': 'allocationTypeName',
  'মাসের নাম': 'monthName',
  'সারের নাম': 'fertilizerName',
  'সারের চাহিদার পরিমাণ': 'requisitionQty',
  'বরাদ্দের পরিমাণ': 'allocationQty',
  'স্টক ব্যালেন্স': 'stockQty'
}

export default {
  name: 'UiDataTable',
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      totalRequisition: 0,
      totalAllocation: 0,
      totalStock: 0,
      dateErrorMsg: '',
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 3,
        fiscal_year_id: 0,
        month_id: 0,
        allocation_type_id: 0,
        fertilizer_type_id: 0,
        fertilizer_id: 0,
        allocation_date: null
      },
      fertilizerNameList: [],
      organization: [],
      excelData: [],
      allocationReport: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingListfertilizer: reportHeadingListfertilizer,
      loading: false
    }
  },
  computed: {
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    },
    allocationTypeList: function () {
        const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
      json_fields: function () {
        return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
      },
      dataCustomizeExcel () {
        var serialNo = 0
        const customizeData = this.allocationReport.map(item => {
          serialNo += 1
          const monupulateData = {
            serial: this.$n(serialNo),
            fiscalYear: this.$i18n.locale === 'en' ? item.fiscal_year : item.fiscal_year_bn,
            allocationTypeName: this.$i18n.locale === 'en' ? item.allocation_type_name : item.allocation_type_name_bn,
            monthName: this.$i18n.locale === 'en' ? item.month_name : item.month_name_bn,
            fertilizerName: this.$i18n.locale === 'en' ? item.fertilizer_name : item.fertilizer_name_bn,
            requisitionQty: this.$i18n.locale === 'en' ? item.requisition_qty : this.$n(item.requisition_qty, { useGrouping: false }),
            allocationQty: this.$i18n.locale === 'en' ? item.allocation_qty : this.$n(item.allocation_qty, { useGrouping: false }),
            stockQty: this.$i18n.locale === 'en' ? item.stock_qty : this.$n(item.stock_qty, { useGrouping: false })
          }
          return Object.assign({}, item, monupulateData)
        })
        return customizeData
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
          { label: this.$t('fertilizerSales.allocationType'), class: 'text-left' },
          { label: this.$t('pusti_mapping.month'), class: 'text-left' },
          { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-left' },
          { label: this.$t('dealer_panel.requisition_amount'), class: 'text-left' },
          { label: this.$t('dealer_management.allocation_amount'), class: 'text-left' },
          { label: this.$t('dealer_panel.stock_balance'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'allocation_type_name_bn' },
          { key: 'month_name_bn' },
          { key: 'fertilizer_name_bn' },
          { key: 'requisition_qty' },
          { key: 'allocation_qty' },
          { key: 'stock_qty' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'allocation_type_name' },
          { key: 'month_name' },
          { key: 'fertilizer_name' },
          { key: 'requisition_qty' },
          { key: 'allocation_qty' },
          { key: 'stock_qty' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    fertilizerTypeList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerTypeList.filter(item => item.status === 1)
    }
  },
   watch: {
    'search.fertilizer_type_id': function (newVal, oldVal) {
      this.fertilizerNameList = this.getFertilizerList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    getFertilizerList (fertilizerTypeId) {
      const fertilizerNameList = this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
      if (fertilizerTypeId) {
        return fertilizerNameList.filter(fertilizerName => fertilizerName.fertilizer_type_id === fertilizerTypeId)
      }
      return fertilizerNameList
    },
   loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, fertilizerAllotmentReport, this.search).then(response => {
        if (response.success) {
            this.allocationReport = this.getRelationalData(response.data)
        } else {
          this.allocationReport = []
          this.totalRequisition = 0
          this.totalAllocation = 0
          this.totalStock = 0
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
      })
    },
    getRelationalData (data) {
      const fiscalYears = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList
      const allocationTypeList = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList
      let requisitionQty = 0
      let allocationQty = 0
      let dealerStockQty = 0
      const listData = data.map(item => {
        requisitionQty += item.requisition_qty
        allocationQty += item.allocation_qty
        dealerStockQty += item.stock_qty
        const fiscalYearObj = fiscalYears.find(doc => doc.value === item.fiscal_year_id)
        const allocationTypeObj = allocationTypeList.find(data => data.value === item.allocation_type_id)
        const monthObj = this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear.find(data => data.value === parseInt(item.month))
        const fiscalYearData = {}
        if (fiscalYearObj !== undefined) {
          fiscalYearData.fiscal_year = fiscalYearObj.text_en
          fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          fiscalYearData.fiscal_year = ''
          fiscalYearData.fiscal_year_bn = ''
        }
        const allocationTypeData = {}
        if (allocationTypeObj !== undefined) {
          allocationTypeData.allocation_type_name = allocationTypeObj.text_en
          allocationTypeData.allocation_type_name_bn = allocationTypeObj.text_bn
        } else {
          allocationTypeData.allocation_type_name = ''
          allocationTypeData.allocation_type_name_bn = ''
        }
        const monthData = {}
        if (monthObj !== undefined) {
          monthData.month_name = monthObj.text_en
          monthData.month_name_bn = monthObj.text_bn
        } else {
          monthData.month_name = ''
          monthData.month_name_bn = ''
        }

        return Object.assign({}, item, fiscalYearData, allocationTypeData, monthData)
      })
      this.totalRequisition = requisitionQty
      this.totalAllocation = allocationQty
      this.totalStock = dealerStockQty
      return listData
    },
    // pdfExport () {
    //   const rowData = this.getPdfData()
    //   const labels = [
    //       { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('fertilizerConfig.fertilizer_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('dealer_panel.requisition_amount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('dealer_management.allocation_amount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
    //       { text: this.$t('dealer_panel.stock_balance'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
    //     ]
    //   rowData.unshift(labels)
    //   const reportTitle = this.$i18n.locale === 'en' ? 'Fertilizer Allotment Report' : 'সার বরাদ্দের রিপোর্ট'
    //   const columnWids = ['7%', '*', '*', '*', '*']
    //   ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, reportHeadingListfertilizer, this.search.org_id, reportTitle, rowData, columnWids)
    // },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'fertilizer_name' : 'fertilizer_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'requisition_qty' : 'requisition_qty' },
          { key: this.$i18n.locale === 'en' ? 'allocation_qty' : 'allocation_qty' },
          { key: this.$i18n.locale === 'en' ? 'stock_qty' : 'stock_qty' }
        ]
      var serial = 0
      const listData = this.allocationReport.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'requisition_qty') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'allocation_qty') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'stock_qty') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Fertilizer Allotment Report' : 'সার বরাদ্দের রিপোর্ট'
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', this.search.org_id, reportTitle, this.allocationReport, this, this.search)
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
